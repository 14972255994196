i.fa-check{
    color: limegreen
}

i.fa-times{
    color: red
}

span {
    white-space: nowrap;
}