$status-backgorund-colours: (
  'queued': lightgray,
  'delivered': #17c671,
  'sent': lighten( #17c671, 20%),
  'undelivered': #c4183c,
  "default": #00b8d8
);

$status-text-colours: (
  'delivered': white,
  "default": black
);

.justify {
  display : flex;
  justify-content: space-between;
}

.messages {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.clickable {
  color : black;
  cursor : pointer;
  text-decoration: underline;
}
.alignLeft {
  justify-content: left;
}

.alignRight {
  text-align: right;
}

.boxDisplay {
  width: 90%;
  margin: 0 10px 0 10px;
  padding: 0 10px 0 10px;

}

.message {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

.yours {
  align-items: flex-start;
  .message {
    margin-right: 50%;
    background-color: #eee;
    position: relative;

    &.last:before {
      content: "";
      position: absolute;
      z-index: 0;
      bottom: 0;
      left: -7px;
      height: 20px;
      width: 20px;
      background: #eee;
      border-bottom-right-radius: 15px;
    }

    &.last:after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: -10px;
      width: 10px;
      height: 20px;
      background: white;
      border-bottom-right-radius: 10px;
    }
  }
}


.mine {
  align-items: flex-end;
}

.mine {
  .message {
    color: map-get($status-text-colours, "default");;
    margin-left: 50%;
    background: map-get($status-backgorund-colours, "default");
    // background: linear-gradient(to bottom, rgb(117, 234, 0) 0%, rgb(57, 213, 5) 100%);
    background-attachment: fixed;
    position: relative;

    &.last:before {
      content: "";
      position: absolute;
      z-index: 0;
      bottom: 0;
      right: -8px;
      height: 20px;
      width: 20px;
      // background: linear-gradient(to bottom, rgb(117, 234, 0) 0%, rgb(57, 213, 5) 100%);
      background-attachment: fixed;
      border-bottom-left-radius: 15px;
    }

    &.last:after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      right: -10px;
      width: 10px;
      height: 20px;
      background: white;
      border-bottom-left-radius: 10px;
    }
  }

  // @each $status, $colour in $status-colours {
  //   &.status-#{status} .message  {
  //     background: $colour !important;
  //   }
  // }


  &.status-queued .message {
    background: map-get($status-backgorund-colours, "queued");
    color: map-get($status-text-colours, "queued");
  }
  &.status-delivered .message, {
    background: map-get($status-backgorund-colours, "delivered");
    color: map-get($status-text-colours, "delivered");
  }
  &.status-sent .message, {
    background: map-get($status-backgorund-colours, "sent");
    color: map-get($status-text-colours, "sent");
  }
  &.status-undelivered .message {
    background: map-get($status-backgorund-colours, "undelivered");
    color: map-get($status-text-colours, "undelivered");
  }




  // @each $name, $glyph in $icons {
  //   .status-#{$name}{
  //     background: $glyph;
  //   }
  // }

}
