.align-me {
    display: flex;
    align-items: center;
  }

.title-charges {
    font-size : 1.5em;
}

.icon-charges {
    font-size : 1em; 
}