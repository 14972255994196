#search-results-overlay {
  display    : none;
  width      : 83.6666666%;
  left       : 16.6666666%;
  background : darken(gray, 15%);
  background : rgba(90,90,90, 0.9);
  &.show {
    display: flex;
  }

  #search-row-inner-container {
    border-radius: 3px;
    background-color: white;
    margin-top: 4rem;
    margin-left: 2px !important;
    width: 84.66666%;
    max-height: 500px;
    overflow-y: auto;
  }

  a.search-result {
    cursor          : pointer;
    color           : gray;
    text-decoration : none;

    .search-result-inner {
      border-bottom: 1px solid gray;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    &:hover .row {
      // background: darken(white, 15%) !important;
      background-color: #007bff;
      color: white;

      .page-title, .page-subtitle {
        color: white !important;
      }

    }

    &:first-child .search-result-inner {
      padding-top: 0px;
    }

    &:last-child .row.search-result-inner {
      padding-bottom: 0px;
      border-bottom: none !important;
    }
  }
}
