.top-layer {
    display : flex;
    flex-direction: column;
}

.top-right {
  display : flex;
  flex-direction: column;

}

i.fa-check-circle{
    color: limegreen;
}

.field-name {

}

.field-value {

}