.supporter-form {

  &.merge {

    .form-group, input, select {
      cursor: pointer !important;

    }


    .merge-selected {
      background-color: lightgreen;
      border-radius: 5px;
    }

    .merge-unselected {
      background-color: white;
      border-radius: 5px;

      &.disable {
        background: lightgrey;
        border-radius: 0px;
        cursor: not-allowed !important;
        input, select, .badge, label, div {
          cursor: not-allowed !important;
        }
      }
    }
  }

}
