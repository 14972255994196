.bottom-action-bar {
  display: none;
  position: fixed;
  bottom: 0;

  @media (max-width: 767px) {
    width: 100%;
    left: 0;
  }
  @media (min-width: 768px) {
    width: 75%;
    left: 25%
  }

  @media (min-width: 992px) {
    left:  16.666667%;
    width: 83.444443%;
  }

  height: 70px;
  padding: 20px;
  background-color: white;
  text-align: center;



  &.show {
    display: block;
  }

  .action-bar-inner {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .btn {
      margin: auto 10px;
    }
  }
}
