.conversation {
  font-family: helvetica;
  display: flex ;
  flex-direction: column;
  align-items: center;
}

.chat {
  /* width: 600px; */
  border: solid 1px #EEE;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.scroll-container {
  height: 400px;
}